import React from 'react';

const IconLogo = () => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 126.23 126.24"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <polygon
          style={{ fill: '#64ffda' }}
          points="98.75 40.5 73.28 54.64 73.28 104.06 48.72 90.19 48.72 46.65 33.96 54.85 43.79 60.39 43.79 67.12 21.97 54.81 54.58 36.69 54.58 86.76 67.43 94.02 67.43 51.19 86.75 40.46 73.41 32.93 60.43 40.14 60.43 33.44 73.43 26.23 98.75 40.5"
        />
        <path
          style={{ fill: '#004568' }}
          d="m88.68,5.39l-6.06,3.36c-8.43-3.03-17.17-3.95-25.62-3.05l-.58-5.33c10.67-1.16,21.76.37,32.26,5.02Z"
        />
        <path
          style={{ fill: '#35c1ec' }}
          d="m115.93,86.53l4.91,2.18c4.66-10.5,6.19-21.61,5.03-32.28l-5.34.57c1.06,9.77-.34,19.93-4.6,29.54Z"
        />
        <path
          style={{ fill: '#1e6285' }}
          d="m56.99,5.71l-.57-5.34c-11,1.19-21.53,5.25-30.48,11.75l3.16,4.34c8.19-5.95,17.82-9.66,27.88-10.75Z"
        />
        <path
          style={{ fill: '#8cd5eb' }}
          d="m125.87,56.43l-5.34.57c-1.09-10.05-4.79-19.65-10.72-27.84l.59-.32,4.14-2.29c6.25,8.82,10.16,19.13,11.34,29.89Z"
        />
        <path
          style={{ fill: '#1e6285' }}
          d="m69.24,120.53l.57,5.34c11-1.19,21.53-5.25,30.48-11.75l-3.16-4.34c-8.19,5.95-17.82,9.66-27.88,10.75Z"
        />
        <path
          style={{ fill: '#004568' }}
          d="m69.81,125.87c-10.67,1.15-21.77-.38-32.27-5.03l6.05-3.36c8.44,3.04,17.18,3.96,25.65,3.05l.57,5.34Z"
        />
        <path
          style={{ fill: '#3eaed9' }}
          d="m120.83,88.71l-4.91-2.18h0c-4.26,9.61-10.85,17.47-18.8,23.24l3.16,4.34c8.69-6.31,15.89-14.9,20.54-25.41h0Z"
        />
        <path
          style={{ fill: '#8cd5eb' }}
          d="m16.44,97.1l-4.72,2.62C5.46,90.9,1.53,80.57.37,69.81l5.33-.57c1.09,10.05,4.8,19.67,10.74,27.86Z"
        />
        <path
          style={{ fill: '#3eaed9' }}
          d="m29.11,16.46l-3.16-4.34c-8.69,6.31-15.89,14.9-20.54,25.41l4.91,2.18c4.26-9.61,10.85-17.47,18.8-23.24Z"
        />
        <path
          style={{ fill: '#35c1ec' }}
          d="m10.31,39.71h0s-4.91-2.18-4.91-2.18h0C.74,48.03-.79,59.14.37,69.81l5.34-.57c-1.06-9.77.34-19.93,4.6-29.54Z"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
